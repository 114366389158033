var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid row"},[_c('div',{staticClass:"flex xs12"},[_c('va-card',{staticClass:"fill-height",attrs:{"title":"Value Edit"}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"flex xs12"},[_c('va-tabs',{staticStyle:{"width":"100%","min-width":"250px","margin-bottom":"25px"},model:{value:(_vm.tabValue),callback:function ($$v) {_vm.tabValue=$$v},expression:"tabValue"}},_vm._l((_vm.tabTitles),function(title){return _c('va-tab',{key:title},[_vm._v(" "+_vm._s(title)+" ")])}),1),(_vm.tabValue === 0)?_c('div',{staticClass:"flex xs12"},[([
                  'aboutHonest',
                  'firstCounter',
                  'rowCounter',
                  'rowCounter2',
                  'titleCounter',
                  'firstCounter2',
                  'lastCounter',
                  'homeCounter',
                  'firstCounter3',
                  'audiCounter'
                ].includes(this.$attrs.field))?_c('va-input',{attrs:{"label":"label *","messages":['The recommended number of characters is 15'],"error":!!_vm.errors.label,"error-messages":_vm.errors.label},on:{"input":function($event){delete _vm.errors.label}},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}}):_vm._e(),([
                  'aboutTag',
                  'aboutValue',
                  'aboutBasis',
                  'aboutBasis2',
                  'aboutEnterprise',
                  'aboutHonest',
                  'aboutPrinsip',
                  'aboutPrinsip2',
                  'aboutPro',
                  'firstCounter',
                  'rowCounter',
                  'rowCounter2',
                  'titleCounter',
                  'cramb2',
                  'firstCounter2',
                  'lastCounter',
                  'product',
                  'homeCounter',
                  'firstCounter3',
                  'audiOdesa',
                  'lider',
                  'cramb'
                ].includes(this.$attrs.field))?_c('va-input',{attrs:{"label":"description *","messages":['The recommended number of characters is 15'],"error":!!_vm.errors.description,"error-messages":_vm.errors.description},on:{"input":function($event){delete _vm.errors.description}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}):_vm._e(),([
                  'firstCounter',
                  'rowCounter',
                  'rowCounter2',
                  'titleCounter',
                  'firstCounter2',
                  'lastCounter',
                  'product',
                  'homeCounter',
                  'firstCounter3'
                ].includes(this.$attrs.field))?_c('va-input',{attrs:{"label":"additional_desc *","messages":['The recommended number of characters is 15'],"error":!!_vm.errors.additional_desc,"error-messages":_vm.errors.additional_desc},on:{"input":function($event){delete _vm.errors.additional_desc}},model:{value:(_vm.additional_desc),callback:function ($$v) {_vm.additional_desc=$$v},expression:"additional_desc"}}):_vm._e(),([''].includes(this.$attrs.field))?_c('va-input',{attrs:{"label":"text *","messages":['The recommended number of characters is 15'],"error":!!_vm.errors.text,"error-messages":_vm.errors.text},on:{"input":function($event){delete _vm.errors.text}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_vm._e(),([
                  'aboutTag',
                  'aboutValue',
                  'aboutBasis',
                  'aboutBasis2',
                  'aboutEnterprise',
                  'aboutHonest',
                  'aboutPrinsip',
                  'aboutPrinsip2',
                  'aboutPro',
                  'firstCounter',
                  'rowCounter',
                  'rowCounter2',
                  'titleCounter',
                  'firstCounter2',
                  'lastCounter',
                  'product',
                  'audiOdesa',
                  'lider'
                ].includes(this.$attrs.field))?_c('va-card',{staticClass:"mb-3",attrs:{"title":"Image *"}},[(_vm.imgExisting)?_c('div',[_c('a',{staticStyle:{"display":"block"},attrs:{"target":"_blank","href":_vm.imgExisting}},[_c('img',{staticStyle:{"max-width":"400px"},attrs:{"src":_vm.imgExisting}})]),_c('div',[_c('va-button',{attrs:{"small":"","color":"danger"},on:{"click":function($event){_vm.imgExisting = _vm.deleteImage(_vm.imgId) ? '' : _vm.imgExisting}}},[_vm._v("Видалити")])],1)]):_vm._e(),_c('span',[_vm._v("Завантажити нове зображення")]),_c('va-file-upload',{staticStyle:{"margin-bottom":"25px"},attrs:{"type":"gallery","dropzone":""},model:{value:(_vm.img),callback:function ($$v) {_vm.img=$$v},expression:"img"}}),_c('va-button',{on:{"click":function($event){return _vm.uploadImage(_vm.img[0])}}},[_vm._v("Завантажити")])],1):_vm._e(),_c('va-button',{on:{"click":function($event){return _vm.submit()}}},[_vm._v("Save")])],1):_vm._e()],1)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }